import AppBar, {AppBarProps} from '@mui/material/AppBar'
import {ReactNode} from 'react'
import Link from '../link'

export type HeaderProps = AppBarProps & {
  profile?: ReactNode
}

export default function Header({children, sx, ...props}: HeaderProps) {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      aria-labelledby="header-title"
      sx={{
        display: `grid`,
        gridAutoFlow: `column`,
        justifyContent: `space-between`,
        alignItems: `center`,
        py: 2,
        px: 3,
        borderBottomWidth: 1,
        borderBottomStyle: `solid`,
        borderBottomColor: `grey.300`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        ...sx,
      }}
      {...props}
    >
      <Link id="header-title" href="/">
        Competency Inventory
      </Link>

      {children}
    </AppBar>
  )
}
