import MatricesIcon from '@mui/icons-material/BlurOnOutlined'
import OrganizationsIcon from '@mui/icons-material/BusinessCenterOutlined'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import TemplatesIcon from '@mui/icons-material/InsertDriveFileOutlined'
import SearchTermsIcon from '@mui/icons-material/ManageSearchOutlined'
import UserGroupsIcon from '@mui/icons-material/PeopleOutlined'
import Box, {BoxProps} from '@mui/material/Box'
import Drawer, {DrawerProps} from '@mui/material/Drawer'
import List, {ListProps} from '@mui/material/List'
import {Theme} from '@mui/material/styles'
import {SxProps} from '@mui/system'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {hasAdminRole, hasAnyRoles} from '../../lib/has-role'
import {UserAccount} from '../../types/user.types'
import MenuItem from '../menu-item'

export type MenuBarProps = DrawerProps & {
  /**
   * Props passed to the inner list wrapper component.
   */
  listWrapperProps?: BoxProps
  /**
   * Props passed to the inner list component.
   */
  listProps?: ListProps
  /**
   * Currently logged in user.
   */
  user?: UserAccount
  /**
   * Determines whether or not the sidebar is open.
   */
  open?: boolean
  /**
   * Function to be called when sidebar open button is being pressed. Button
   * won't be rendered unless this function is provided.
   */
  onOpen?: (open: boolean) => void
}

/**
 * Animation timeout in milliseconds.
 */
const COLLAPSE_TIMEOUT = 250

/**
 * Width of sidebar when it is open.
 */
const OPEN_SIDEBAR_WIDTH = 248

/**
 * Width of sidebar when it is closed.
 */
const CLOSED_SIDEBAR_WIDTH = 92

export default function MenuBar({
  listWrapperProps,
  listProps,
  user,
  sx,
  open,
  onOpen,
  ...props
}: MenuBarProps) {
  const {t} = useTranslation('common')
  const {pathname} = useRouter()
  const drawerWidth = open ? OPEN_SIDEBAR_WIDTH : CLOSED_SIDEBAR_WIDTH
  const {sx: listWrapperSx, ...restListWrapperProps} = listWrapperProps || {}

  const [showText, setShowText] = useState(open)
  const defaultMenuSx: SxProps<Theme> = open
    ? {}
    : {
        justifyContent: `center`,
        px: 0,
      }
  const defaultMenuTextSx: SxProps<Theme> = {
    transition: `opacity 0.25s ease-in-out`,
  }
  const menuTextSx: SxProps<Theme> = showText
    ? {...defaultMenuTextSx, opacity: 1}
    : {...defaultMenuTextSx, opacity: 0, whiteSpace: `nowrap`}

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (open) {
      timeout = setTimeout(() => setShowText(true), COLLAPSE_TIMEOUT)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [open])

  function isActive(path: string, exact?: boolean) {
    if (exact) {
      return pathname === path
    }

    return pathname.startsWith(path)
  }

  function handleOpen() {
    if (onOpen) {
      onOpen(!open)
    }

    if (showText) {
      setShowText(false)
    }
  }

  return (
    <Drawer
      variant="permanent"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          paddingTop: 8.5,
          paddingBottom: 2.5,
          width: drawerWidth,
          transition: `width ${COLLAPSE_TIMEOUT}ms ease-in-out`,
          boxSizing: `border-box`,
          justifyContent: `space-between`,
          [`&:after`]: {
            position: `absolute`,
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), white)`,
            height: 40,
            width: `100%`,
            bottom: `17.5%`,
            left: 0,
            right: 0,
            content: `""`,
          },
        },
        ...sx,
      }}
      {...props}
    >
      <Box
        component="nav"
        aria-label="Primary navigation"
        sx={{
          position: `relative`,
          overflowX: `hidden`,
          overflowY: `auto`,
          ...listWrapperSx,
        }}
        {...restListWrapperProps}
      >
        <List {...listProps}>
          <MenuItem
            icon={<DashboardIcon />}
            active={isActive(`/`, true)}
            href="/"
            sx={defaultMenuSx}
            textProps={{sx: menuTextSx}}
            tooltip={!open && t(`menu.dashboard`)}
          >
            {open && t(`menu.dashboard`)}
          </MenuItem>

          {hasAnyRoles(user, `ROLE_CONTENT_ADMIN`, `ROLE_ADMIN`) && (
            <MenuItem
              icon={<TemplatesIcon />}
              active={isActive(`/templates`)}
              href="/templates"
              sx={defaultMenuSx}
              textProps={{sx: menuTextSx}}
              tooltip={!open && t(`menu.templates`)}
            >
              {open && t(`menu.templates`)}
            </MenuItem>
          )}

          {hasAdminRole(user) && (
            <MenuItem
              icon={<UserGroupsIcon />}
              active={isActive(`/user-groups`)}
              href="/user-groups"
              sx={defaultMenuSx}
              textProps={{sx: menuTextSx}}
              tooltip={!open && t(`menu.userGroups`)}
            >
              {open && t(`menu.userGroups`)}
            </MenuItem>
          )}

          <MenuItem
            icon={<OrganizationsIcon />}
            active={isActive(`/organizations`)}
            href="/organizations"
            sx={defaultMenuSx}
            textProps={{sx: menuTextSx}}
            tooltip={!open && t(`menu.organizations`)}
          >
            {open && t(`menu.organizations`)}
          </MenuItem>

          {hasAnyRoles(user, `ROLE_SALES`, `ROLE_ADMIN`) && (
            <MenuItem
              icon={<SearchTermsIcon />}
              active={isActive(`/search-terms`)}
              href="/search-terms"
              sx={defaultMenuSx}
              textProps={{sx: menuTextSx}}
              tooltip={!open && t(`menu.searchTerms`)}
            >
              {open && t(`menu.searchTerms`)}
            </MenuItem>
          )}

          <MenuItem
            icon={<MatricesIcon />}
            active={isActive(`/skill-matrices`)}
            href="/skill-matrices"
            sx={defaultMenuSx}
            textProps={{sx: menuTextSx}}
            tooltip={!open && t(`menu.skillMatrices`)}
          >
            {open && t(`menu.skillMatrices`)}
          </MenuItem>
        </List>
      </Box>
    </Drawer>
  )
}
