import Box, {BoxProps} from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import {NextSeo, NextSeoProps} from 'next-seo'
import {ReactNode} from 'react'
import defaultSeoConfig from '../../next-seo.config'

export type LayoutBaseProps = BoxProps & {
  /**
   * Header component to be rendered.
   */
  header?: ReactNode
  /**
   * Sidebar component to be rendered.
   */
  sidebar?: ReactNode
  /**
   * Props passed to the SEO component.
   */
  seoProps?: NextSeoProps
  /**
   * Footer component to be rendered.
   */
  footer?: ReactNode
}

function LayoutBase({
  header,
  sidebar,
  footer,
  children,
  seoProps,
  sx,
  ...props
}: LayoutBaseProps) {
  return (
    <>
      <NextSeo
        defaultTitle="Competency Inventory"
        titleTemplate="%s - Competency Inventory"
        openGraph={{...defaultSeoConfig.openGraph}}
        {...seoProps}
      />
      <CssBaseline />

      {header}

      {sidebar}

      <Box sx={{minHeight: `100vh`, ...sx}} {...props}>
        {children}
      </Box>

      {footer}
    </>
  )
}

export default LayoutBase
