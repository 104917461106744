import {useRouter} from 'next/router'
import {useEffect} from 'react'
import useSWR from 'swr'
import getNetworkStatus from '../../lib/get-network-status'
import type {UserAccount} from '../../types/user.types'

export type UseUserProps = {
  /**
   * URL to redirect to if a valid user object is found.
   */
  redirectTo?: string
  /**
   * Whether to redirect if a valid user object is found. It's useful not to
   * redirect if the user is already on a page where authentication is required.
   */
  redirectIfFound?: boolean
  /**
   * Referrer URL.
   */
  referrer?: string
  /**
   * Prevent redirecting to these referrer URLs. Useful if it's unnecessary to
   * redirect to a page after authentication (e.g: 404, 500, etc.).
   */
  excludedReferrers?: Array<string>
}

const AUTH_TOKEN_STORAGE_KEY = `token`

type UseRemoveExpiredJWTProps = {
  /**
   * Error retrieved from fetching user data.
   */
  userError?: Error
  /**
   * Function to remove expired JWT token from local or session storage.
   */
  signOut: () => Promise<void>
}

/**
 * Hook to remove expired JWT token from local or session storage.
 */
function useRemoveExpiredJWT({userError, signOut}: UseRemoveExpiredJWTProps) {
  useEffect(() => {
    if (userError?.message === 'expired.jwt' && typeof window !== 'undefined') {
      signOut()
    }
  }, [signOut, userError])
}

/**
 * Hook to redirect user to the correct URL if a valid user object is found.
 */
function useUserAndRedirectIfFound({
  redirectTo,
  redirectIfFound,
  referrer,
  excludedReferrers,
}: UseUserProps) {
  const router = useRouter()
  const {
    data: user,
    error: userError,
    mutate: mutateUser,
    isValidating,
  } = useSWR<UserAccount>(
    `${process.env.NEXT_PUBLIC_API_URL}/api/user/logged-in`,
    {
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  )

  useEffect(() => {
    if (isValidating || !redirectTo) return

    if ((redirectIfFound && user) || (!redirectIfFound && !user)) {
      router.push({
        pathname: redirectTo,
        query:
          referrer && excludedReferrers.indexOf(referrer) === -1
            ? {referrer}
            : undefined,
      })
    }
  }, [
    excludedReferrers,
    isValidating,
    redirectIfFound,
    redirectTo,
    referrer,
    router,
    user,
  ])

  return {user, userError, mutateUser, isValidating}
}

export default function useUser({
  redirectTo = '',
  redirectIfFound = false,
  referrer = '',
  excludedReferrers = ['/', '/404', '/500'],
}: UseUserProps) {
  const router = useRouter()

  const {user, userError, mutateUser, isValidating} = useUserAndRedirectIfFound(
    {redirectTo, redirectIfFound, referrer, excludedReferrers},
  )

  useRemoveExpiredJWT({userError, signOut})

  async function signOut() {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)) {
        window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
      } else if (window.sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY)) {
        window.sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
      }
    }

    await mutateUser(null)
    await router.push(`/login`)
  }

  return {
    user,
    userError,
    mutateUser,
    isValidating,
    referrer,
    signOut,
    networkStatus: getNetworkStatus(user, userError, isValidating),
  }
}
