import CoreLink, {CoreLinkProps} from '@bishop/core-ui/core-link'
import NextLink from 'next/link'
import {ForwardedRef, forwardRef} from 'react'

export type LinkProps = CoreLinkProps & {
  /**
   * Determines whether or not the target page should be prefetched. Works only
   * if the target page is part of the application.
   *
   * @default false
   */
  prefetch?: boolean
}

function Link(
  {prefetch = false, href, children, ...props}: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <NextLink prefetch={prefetch} href={href} passHref>
      <CoreLink ref={ref} {...props}>
        {children}
      </CoreLink>
    </NextLink>
  )
}

export default forwardRef(Link)
