import CoreActivityIndicator from '@bishop/core-ui/core-activity-indicator'
import CoreErrorDisplay from '@bishop/core-ui/core-error-display'
import CorePopover from '@bishop/core-ui/core-popover'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListSubheader from '@mui/material/ListSubheader'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'
import {useState} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import Header from '../../components/header'
import MenuBar from '../../components/menu-bar'
import MenuItem from '../../components/menu-item'
import useUser from '../../hooks/use-user'
import LayoutBase, {LayoutBaseProps} from '../layout-base'

export type AuthenticatedLayoutProps = LayoutBaseProps

const SIDEBAR_STORAGE_KEY = `sidebarOpen`

export default function AuthenticatedLayout({
  children,
  sx,
  seoProps,
  ...props
}: AuthenticatedLayoutProps) {
  const {t} = useTranslation('common')
  const {title, titleTemplate, ...restSeoProps} = seoProps || {}
  const router = useRouter()
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    return true

    // note: temporarily disabling sidebar open / close functionality
    // return typeof window !== 'undefined'
    //   ? window.localStorage.getItem(SIDEBAR_STORAGE_KEY) === 'true'
    //   : undefined
  })
  const {user, signOut, networkStatus} = useUser({
    redirectIfFound: false,
    redirectTo: `/login`,
    referrer: router.asPath,
  })

  // note: temporarily disabling sidebar open / close functionality
  // function handleSidebarOpen(open: boolean) {
  //   const storage =
  //     typeof window !== 'undefined' ? window.localStorage : undefined

  //   if (storage) {
  //     storage.setItem(SIDEBAR_STORAGE_KEY, String(open))
  //   }

  //   setSidebarOpen(open)
  // }

  if (
    networkStatus === `pending` ||
    networkStatus === `rejected` ||
    sidebarOpen === undefined
  ) {
    return (
      <LayoutBase
        sx={{
          display: `grid`,
          gridAutoFlow: `column`,
          gap: 2,
          alignItems: `center`,
          justifyContent: `center`,
          padding: {xs: 3, md: 34},
          ...sx,
        }}
        aria-busy={true}
        seoProps={{
          title: `Competency Inventory`,
          titleTemplate: null,
          ...restSeoProps,
        }}
        {...props}
      >
        <CoreActivityIndicator
          progressIndicatorProps={{'aria-label': t(`a11y.loadingUser`)}}
        >
          {t(`label.loading`)}
        </CoreActivityIndicator>
      </LayoutBase>
    )
  }

  return (
    <LayoutBase
      header={
        networkStatus === `resolved` && (
          <Header>
            <CorePopover
              buttonLabel={user?.name || `Unknown`}
              anchorOrigin={{vertical: `bottom`, horizontal: `right`}}
              transformOrigin={{vertical: `top`, horizontal: `right`}}
              sx={{minWidth: 180, [`& .MuiPaper-root`]: {width: 180}}}
            >
              <List sx={{pt: 0, pb: 1}}>
                <ListSubheader>
                  <strong>{user?.name}</strong>
                </ListSubheader>
                <MenuItem href="/profile">{t(`profile.edit`)}</MenuItem>

                <ListItemButton disableGutters sx={{px: 3}} onClick={signOut}>
                  {t(`profile.signOut`)}
                </ListItemButton>
              </List>
            </CorePopover>
          </Header>
        )
      }
      sidebar={
        <MenuBar
          sx={{
            display: {xs: `none`, md: `block`},
          }}
          user={user}
          open={sidebarOpen}
          // note: temporarily disabling sidebar open / close functionality
          // onOpen={handleSidebarOpen}
        />
      }
      sx={{
        paddingTop: 12,
        paddingBottom: 8,
        paddingLeft: sidebarOpen ? {xs: 3, md: 34} : {xs: 3, md: 15},
        paddingRight: 3,
        transition: `padding-left 0.25s ease-in-out`,
        ...sx,
      }}
      seoProps={seoProps}
      {...props}
    >
      <ErrorBoundary
        fallbackRender={({error, resetErrorBoundary}) => (
          <CoreErrorDisplay
            id="authenticated-layout-error"
            revalidate={resetErrorBoundary}
          >
            {error.message}
          </CoreErrorDisplay>
        )}
      >
        {children}
      </ErrorBoundary>
    </LayoutBase>
  )
}
