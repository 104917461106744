import {UserAccount, UserRole} from '../../types/user.types'

export const hasAdminRole = hasRole('ROLE_ADMIN')
export const hasContentAdminRole = hasRole('ROLE_CONTENT_ADMIN')
export const hasSalesRole = hasRole('ROLE_SALES')

export function hasAnyRoles(user: UserAccount, ...anyRoles: Array<UserRole>) {
  if (!user) {
    return false
  }

  return user.roles.filter((role) => anyRoles.includes(role)).length > 0
}

/**
 * Returns a function which accepts a `user` and returns true if the user has the
 * specified role.
 *
 * @param role - Role to check for
 * @returns Function which accepts a `user` and returns true if the user has the
 * specified role
 */
function hasRole(role: UserRole) {
  return (user: UserAccount) => {
    if (!user) {
      return false
    }

    return user.roles.indexOf(role) > -1
  }
}

export default hasRole
