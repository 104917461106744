import CoreIconBadge from '@bishop/core-ui/core-icon-badge'
import CoreMenuItem, {CoreMenuItemProps} from '@bishop/core-ui/core-menu-item'
import CoreTooltip, {CoreTooltipProps} from '@bishop/core-ui/core-tooltip'
import {Theme} from '@mui/material/styles'
import {SxProps} from '@mui/system'
import {ForwardedRef, forwardRef} from 'react'
import Link from '../link'

export type MenuItemProps = Omit<CoreMenuItemProps, 'component' | 'ref'> & {
  /**
   * Label of tooltip. Tooltip will not be rendered unless this property is
   * provided.
   */
  tooltip?: string
  /**
   * Props passed to the tooltip component.
   */
  tooltipProps?: Omit<CoreTooltipProps, 'title'>
}

export default forwardRef(function MenuItem(
  {children, icon, sx, tooltip, tooltipProps, ...props}: MenuItemProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const iconBadgeSx: SxProps<Theme> = props.active
    ? {
        color: `common.white`,
        backgroundColor: `primary.main`,
      }
    : {}

  const menuItem = (
    <CoreMenuItem
      ref={ref}
      sx={{
        display: `grid`,
        gridAutoFlow: `column`,
        justifyContent: `start`,
        gap: 1.5,
        ...sx,
      }}
      iconProps={{sx: {minWidth: 0}}}
      component={Link}
      icon={icon && <CoreIconBadge sx={iconBadgeSx}>{icon}</CoreIconBadge>}
      {...props}
    >
      {children}
    </CoreMenuItem>
  )

  if (!tooltip) {
    return menuItem
  }

  return (
    <CoreTooltip
      describeChild
      title={tooltip}
      placement="right"
      {...tooltipProps}
    >
      {menuItem}
    </CoreTooltip>
  )
})
